import React, { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { Button, Image, Tooltip } from 'antd';
import { FiChevronDown, FiClock } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import edgeLogo from '@images/edge_logo.png';
import useAvatar from 'vl-common/src/hooks/useAvatar';
import useUserNextAppointments from '@hooks/useUserNextAppointments';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import isWithinInterval from 'date-fns/isWithinInterval';
import subMinutes from 'date-fns/subMinutes';
import addMinutes from 'date-fns/addMinutes';
import useAppointmentCountdown from '@hooks/useAppointmentCountdown';
import useOtherPartyStatus, { usePartyStatus } from '@hooks/useOtherPartyStatus';
import useMyStatusUpdater from '@hooks/useMyStatusUpdater';
import useDelayAppointment from '@hooks/useDelayAppointment';
import useAppointmentDelays from '@hooks/useAppointmentDelays';
import useCallStatus from '@hooks/useCallStatus';
import useAudio from '@hooks/useAudio';
import useMyRole from '@hooks/useMyRole';
import * as actions from '@actions';
import { CancelAppointmentModal } from '@components/Documents/ActiveDocuments';
import * as actionTypes from '@actionTypes';
import useCancelledNotification from '@hooks/useCancelledNotification';
import { getLogger } from 'loglevel';
import Datetime from 'vl-common/src/components/Datestamp';
import { CloseCircleOutlined, PhoneOutlined } from 'antd/icons';
import useCallStatusUpdaters from '@hooks/useCallStatusUpdaters';
import useTodaysAppointments from '@hooks/useTodaysAppointments';
import { useRuntimeConfig } from 'vl-core/useRuntimeConfig';
import useAuth from '@vl-core/useAuth';
import useAppointmentStatus from '@hooks/useAppointmentStatus';
import { css } from '@emotion/react';
import { InfoModal, confirmationModal } from 'vl-common/src/components/Modals';
import useClinicianDiary from '@hooks/useClinicianDiary';
import useUser from '../../vl-core/hooks/useUser';

const log = getLogger(__filename);

function getPageType(pathname) {
  if (pathname.startsWith('/patients')) return 'patients';
  if (pathname.startsWith('/clinician')) return 'clinician';

  return '';
}

function inRange(secondsToStart) {
  const minutesToStart = Math.floor(secondsToStart / 60);

  return minutesToStart > -20 && minutesToStart < 10;
}

function delayStatusFromDelay(delay, rescheduleRequested) {
  if (rescheduleRequested) {
    return 'please reschedule';
  }

  if (delay === '') {
    return 'as scheduled';
  }

  const numericDelay = Number(delay);

  if (numericDelay < 0) return 'early';
  if (numericDelay > 0) return 'delayed';
  return 'on time';
}

function useCallWaitingModal(appointment, play, pause) {
  const { push, pathname } = useRouter();
  const clinicianAvatar = useAvatar(appointment.clinician_user_guid);
  const { callStatus = { status: undefined, reason: undefined } } = useAppointmentStatus(appointment);
  const { reason } = callStatus;
  const appointmentStatus = callStatus?.status || 'not-started';
  const onSessionWindow = pathname.includes('/session');
  const myRole = useMyRole();
  const { startCall, decline } = useCallStatusUpdaters(appointment);
  const dispatch = useDispatch();
  const user = useUser();
  const { appointment_id } = appointment;
  const { TWILIO_EVENT_VISIBILITY } = useRuntimeConfig();

  // log document visibility changes to Twilio events table
  useEffect(() => {
    function visibilityChanged() {
      const state = document.visibilityState;

      dispatch(actions.sendTwilioEvent(user?.user_guid, appointment_id, 'VISIBILITY_CHANGED', state)).then();
    }
    if (!TWILIO_EVENT_VISIBILITY) return () => {};

    document.addEventListener('visibilitychange', visibilityChanged);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChanged);
    };
  }, [TWILIO_EVENT_VISIBILITY, appointment_id, dispatch, user?.user_guid]);

  // log calling filtering reasons to Twilio events table
  useEffect(() => {
    if (reason === 'UNANSWERED' || reason === 'CADECLINED') {
      dispatch(actions.sendTwilioEvent(user?.user_guid, appointment_id, 'IGNORE_CALLING', reason))
        .then()
        .catch();
    }
  }, [appointment_id, dispatch, reason, user?.user_guid]);

  useEffect(() => {
    if (myRole === 'patient' && appointmentStatus === 'calling') {
      const modal = confirmationModal({
        // this needs to be one greater than the Stonly z-Index, otherwise the patient doesn't see
        // the ringer
        zIndex: 2147483003,
        wrapClassName: 'clinician-calling-modal',
        title: `${appointment.clinician} is calling`,
        open: appointmentStatus === 'calling',
        content: (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid grey',
              paddingBottom: 20
            }}
          >
            {/* see https://stackoverflow.com/a/51630171 and https://developer.chrome.com/blog/autoplay/ */}
            <iframe
              title="video call ringer"
              src="/static/silence.mp3"
              allow="autoplay"
              id="audio"
              style={{ display: 'none' }}
            />
            <img
              style={{ width: 150, height: 150, borderRadius: 75, marginInline: 'auto', objectFit: 'cover' }}
              src={clinicianAvatar}
              alt="Clinician"
            />
          </div>
        ),
        okText: 'Answer',
        cancelButtonProps: { style: { color: 'red' }, icon: <CloseCircleOutlined />, 'data-testid': 'decline-call' },
        okButtonProps: { icon: <PhoneOutlined />, 'data-testid': 'answer-call' },
        onCancel: (close) => {
          decline().then();
          pause();
          close();
        },
        onOk: (close) => {
          const encryptid = Buffer.from(`${appointment.appointment_id}`).toString('base64');
          pause();
          startCall().then(() => {
            close();

            if (!onSessionWindow) {
              push(`/patients/session?req=${encryptid}&on=${appointment.call_type_code}`).then();
            }
          });
        }
      });
      dispatch(actions.sendTwilioEvent(user?.user_guid, appointment_id, 'RINGER_DISPLAYED')).then();
      try {
        play();
      } catch (e) {
        console.log(e);
      }
      return () => {
        pause();
        modal.destroy();
      };
    }
    pause();

    return () => {};
  }, [
    onSessionWindow,
    appointmentStatus,
    myRole,
    appointment,
    clinicianAvatar,
    push,
    decline,
    startCall,
    user?.user_guid,
    appointment_id,
    dispatch,
    play,
    pause
  ]);
}

const appointmentWaitStyles = css`
  #slideup-chevron {
    transition: all 0.5s ease-in-out;
    transform: rotate(0deg);
  }

  #slideup-chevron.rotate {
    transform: rotate(180deg);
  }

  width: 500px;
  position: fixed;
  bottom: 0;
  right: 5px;
  background: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 100000000;
  padding-bottom: 10px !important;
  transition: all 1s ease-in-out;
  transform: translate(0, 0);

  &.slideAnim {
    transform: translate(0, calc(100% - 37px));
  }
  .slideup-toolbar {
    position: relative;
    background: var(--productPrimary);
    padding: 10px;
    cursor: pointer;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    p {
      margin: 0;
      font: Bold 14px/16px Raleway;
      color: #fff;
      vertical-align: middle;
    }

    .slideup-clock {
      position: relative;
      top: 2px;
      bottom: 2px;
    }
  }

  .slideup-contents {
    .slideup-main {
      display: table;
      width: 100%;
      background: #f0f0f0 0 0 no-repeat padding-box !important;
      box-shadow: 0 3px 6px #00000029 !important;

      .slideup-patient-img {
        height: 62px;
        width: 62px;
        display: table-cell;
        vertical-align: middle;
        padding-left: 15px;

        svg {
          width: 60px;
          height: 60px;
        }
      }

      .slideup-patient-details {
        display: table-cell;
        vertical-align: middle;
        padding-left: 15px;

        p {
          margin: 0;
          display: table;
        }

        .slideup-patient-name {
          font: Bold 11px/17px Poppins;
          color: #000000;
        }

        .slideup-patient-compl {
          font: 10px/11px Poppins;
          padding-top: 3px;
          letter-spacing: 0;
          color: #000000;
          opacity: 1;
        }

        .their-status {
          font: 10px/11px Poppins;
          padding-top: 3px;
          letter-spacing: 0;
          color: #000000;
          opacity: 1;
        }
      }

      .slideup-timer-section {
        display: table-cell;
        text-align: center;
        border-left: #707070;
        border-width: 0 0 0 1px;
        border-style: solid;
        width: 140px;

        .slideup-timer {
          font: Bold 43px/50px Raleway;
          display: inline-block;
          color: #000000;
        }

        .slideup-minsec {
          text-align: center;
          color: var(--productGray);

          p {
            display: inline-block;
            padding: 0 6px;
            font: lighter 13px/20px Poppins;
          }

          .slideup-min,
          .slideup-sec {
            font: 13px/15px Raleway;
          }
        }
      }
    }

    .slideup-description {
      padding-top: 20px;
      padding-bottom: 11px;
      text-align: center;
      font: 12px/16px Poppins;
      color: #000;

      .review {
        text-decoration: underline;
        font-weight: Bold;
        font-size: 12px;
        font-family: Raleway, serif;
        display: block;
        margin-top: 22px;
      }
    }
  }

  .slideup-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    :global(.button) {
      display: flex;
      margin: 0 5px;
      flex: 1;
      justify-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    .slideup-container {
      width: 100%;
      right: 0;
    }
  }

  .browser-modal {
    min-width: 600px;
    @media only screen and (max-width: 609px) {
      min-width: 400px;
    }

    .ant-btn {
      min-width: 125px;
      margin-top: 20px;
    }

    .logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 80px;
        height: 80px;
        border: 1px solid #f9f9f9;
        padding: 10px;
        border-radius: 25px;
        box-shadow:
          0 3px 6px -4px rgba(0, 0, 0, 0.12),
          0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        p.logo-text {
          margin-top: 15px;
          text-align: center;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
`;

// TODO - split into clinician and patient variants.
const AppointmentWait = ({
  className,
  pageType,
  appointmentWait,
  invalidateUserSchedule,
  onPrevious,
  onNext,
  hasPrevious,
  hasNext,
  visible,
  exposed,
  onExposed,
  onCollapse
}) => {
  const { push, pathname } = useRouter();
  const { upcomingHistory } = useAuth();
  const classNames = cx(exposed ? 'slideup-container' : 'slideup-container slideAnim', className);
  const slideAnimate = () => {
    const container = document.getElementsByClassName('slideup-container')[0];
    const chevron = document.getElementById('slideup-chevron');
    if (container) {
      if (container.classList.contains('slideAnim')) {
        onExposed?.();
        container.classList.remove('slideAnim');
        if (chevron.classList.contains('rotate')) {
          chevron.classList.remove('rotate');
        }
      } else {
        onCollapse?.();
        container.classList.add('slideAnim');
        if (!chevron.classList.contains('rotate')) {
          chevron.classList.add('rotate');
        }
      }
    }
  };
  const { user } = useAuth();
  const [displayRescheduleModal, setDisplayRescheduleModal] = useState(false);
  const avatar = useAvatar(user.user_guid);
  const onSessionWindow = pathname.includes('/session');
  const rescheduleRequested = false;
  const [, setDelay] = useDelayAppointment(appointmentWait);
  const { delayInMinutes } = useAppointmentDelays(appointmentWait);
  const appointmentStatus = useCallStatus(appointmentWait);
  const myRole = useMyRole();
  const patientStatus = usePartyStatus(appointmentWait, 'patient');
  const counter = useAppointmentCountdown(appointmentWait, { delayInMinutes });
  const delay = delayInMinutes * 60;
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const dispatch = useDispatch();
  const [, invalidateNextAppointment] = useUserNextAppointments(1);
  const [, invalidateWaitingAppointments] = useUserNextAppointments(10);
  const router = useRouter();
  const [isCancelled, setCancelled] = useCancelledNotification(appointmentWait);
  const theirStatus = useOtherPartyStatus(appointmentWait);
  const [, play, pause] = useAudio('/static/telephone-ring.mp3');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);

  const invalidateUserAppointments = () => {
    invalidateUserSchedule();
    invalidateWaitingAppointments();
  };

  useMyStatusUpdater(appointmentWait);
  useCallWaitingModal(appointmentWait, play, pause);

  const delayStatus = delayStatusFromDelay(delayInMinutes, rescheduleRequested);

  const requestReschedule = async () => {
    setAppointmentToCancel(appointmentWait);
  };

  useEffect(() => {
    if (delayInMinutes || appointmentStatus === 'completed') {
      invalidateWaitingAppointments();
    }
  }, [delayInMinutes, appointmentStatus, invalidateWaitingAppointments]);

  const cancellationAcknowledged = () => {
    if (myRole === 'patient' && patientStatus === 'in-session') {
      router.back();
    }
    setDisplayRescheduleModal(false);
    invalidateUserAppointments();
  };

  const cancelledAppointment = async (reason) => {
    setAppointmentToCancel(null);
    const reasonResponse = await dispatch(actions.rescheduleCancel(reason));
    if (reasonResponse.type === actionTypes.RESCHEDULE_CANCEL_SUCCESS) {
      setCancelled();
      setTimeout(invalidateUserAppointments, 0);
    } else {
      log.error('Something went wrong in the cancellation');
    }
    slideAnimate();
  };

  useEffect(() => {
    if (isCancelled && myRole === 'patient') {
      setDisplayRescheduleModal(true);
    }
  }, [isCancelled, myRole]);

  const RenderStatus = () => (
    <span style={{ whiteSpace: 'nowrap', fontSize: '0.8rem' }}>
      {' '}
      ({myRole === 'clinician' ? theirStatus : delayStatus})
    </span>
  );

  const RenderTime = ({ withoutStatus = false }) => {
    const minutes = String(Math.floor(counter / 60)).padStart(2, '0');
    const seconds = String(counter % 60).padStart(2, '0');
    return (
      <span>
        {Number(minutes) >= 0 ? `${minutes}:${seconds}` : '00:00'}
        {!withoutStatus && <RenderStatus />}
      </span>
    );
  };

  const CancellationApology = () => {
    return (
      <p>
        We apologise that due to unforeseen circumstances, we have had to cancel this appointment.&nbsp;
        <Link href="/patients/support">Visit Support Page</Link>
      </p>
    );
  };

  const ToggleDelayButton = ({ ...otherProps }) => (
    <Button
      data-testid="delay-button"
      style={delayInMinutes ? { borderColor: 'black', borderWidth: '3px' } : {}}
      onClick={() => {
        setDelay();
        invalidateNextAppointment();
      }}
      {...otherProps}
    >
      Delay by 5 minutes
    </Button>
  );

  const startAppointment = () => {
    slideAnimate();
    if (!appointmentWait) return;
    const encryptid = Buffer.from(`${appointmentWait.appointment_id}`).toString('base64');

    if (myRole === 'clinician') {
      push(`/clinician/session?req=${encryptid}&on=${appointmentWait.call_type_code}`).then();
      return;
    }

    confirmationModal({
      title: 'Helpful Information',
      icon: false,
      className: 'browser-modal',
      okText: 'Proceed to Appointment',
      cancelText: 'Cancel',
      content: (
        <div>
          <p style={{ margin: '20px 0' }}>
            Please be aware that the Virtual Lucy video technology works best on the <b>LATEST</b> versions of the
            following web browsers:
          </p>
          <div className="logos">
            <span className="logo">
              <img
                src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_firefox-128.png"
                alt=""
              />
              <p className="logo-text">Firefox</p>
            </span>
            <span className="logo">
              <img src={edgeLogo} width="180" alt="" />
              <p className="logo-text">Edge</p>
            </span>
            <span className="logo">
              <img
                src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_safari-128.png"
                alt=""
              />
              <p className="logo-text">Safari</p>
            </span>
            <span className="logo">
              <img
                src="https://cdn3.iconfinder.com/data/icons/logos-brands-3/24/logo_brand_brands_logos_chrome-128.png"
                alt=""
              />
              <p className="logo-text">Chrome</p>
            </span>
          </div>
          <p style={{ margin: '80px 0 20px 0' }}>Please note, Microsoft Internet Explorer is not supported.</p>
          <p style={{ margin: '40px 0 20px 0' }}>
            If you experience technical issues connecting to a video appointment, your clinician will be notified and
            they will call you directly on the phone number you have registered with us.
          </p>
          <p style={{ margin: '40px 0 20px 0' }}>
            <AiOutlineInfoCircle style={{ fontSize: 16, marginRight: 4 }} />
            Please ensure that you have access to a web-camera for your appointment
          </p>
        </div>
      ),
      onOk() {
        push(`/patients/session?req=${encryptid}&on=${appointmentWait.call_type_code}`).then();
      }
    });
  };

  const renderTitle = () => {
    const delayTimes = delayInMinutes / 5;
    const start = (
      <span>
        {myRole === 'clinician' && delayInMinutes > 0 && `(${delayTimes} x delayed)`}
        <Datetime date={appointmentWait.start} format="24 hour time - 09:47" />
      </span>
    );

    if (rescheduleRequested) {
      if (pageType === 'patients') {
        return <p>Please reschedule your {start} appointment</p>;
      }

      return <p>You requested your {start} appointment be rescheduled</p>;
    }

    if (appointmentStatus === 'in-progress') {
      return <p>Your {start} appointment has started ...</p>;
    }
    if (appointmentStatus === 'completed') {
      return <p>Your {start} appointment has ended ...</p>;
    }

    if (Math.floor((counter + delay) / 60) < 0) {
      return (
        <p>
          Your {start} appointment is ready to start ...
          <RenderStatus />
        </p>
      );
    }
    return (
      <p>
        Your {start} appointment starts in <FiClock className="slideup-clock" /> <RenderTime />
      </p>
    );
  };

  const renderContent = () => {
    if (appointmentStatus === 'completed') {
      return <p>Your call has ended.</p>;
    }

    if (pageType === 'patients' && rescheduleRequested) {
      return <CancellationApology />;
    }
    if (pageType === 'patients' && appointmentWait.call_type_code === 'AUDIO') {
      // eslint-disable-next-line max-len
      return 'Your appointment is due to start in the next few minutes, your clinician will call you on the phone number registered with us. Please be aware, you will be asked a couple of security questions to confirm your identify before any further details can be discussed about your case.';
    }
    if (pageType === 'patients' && Math.floor(counter / 60) < 0) {
      return (
        <p>
          Your appointment is ready to start. Please click "Go to Appointment Room" to join the session with your
          clinician.
        </p>
      );
    }
    if (pageType === 'patients') {
      if (appointmentStatus === 'in-progress') {
        return 'Your appointment has already started.';
      }

      // eslint-disable-next-line max-len
      return 'Your appointment is almost ready.  You can enter the appointment room 10 minutes before it starts and the clinician will be notified that you are ready.';
    }
    // eslint-disable-next-line max-len
    return 'Your appointment is almost ready. You can enter the Appointment Room 10 minutes before it starts and the patient will be notified that you are ready.';
  };

  const switchAppointment = () => {
    if (hasNext) {
      onNext();
    } else if (hasPrevious) {
      onPrevious();
    }
  };

  if (!visible) {
    return null;
  }

  if (!appointmentWait || typeof counter !== 'number') {
    switchAppointment();
    return null;
  }

  // clinician displays based on the delayed time remaining
  if (pageType === 'clinician' && !inRange(counter)) {
    switchAppointment();
    return null;
  }

  // patient displays based on the unadjusted start of the appointment
  if (pageType === 'patients' && !inRange(differenceInSeconds(new Date(appointmentWait.datetime), new Date()))) {
    switchAppointment();
    return null;
  }

  if (pageType !== 'patients' && pageType !== 'clinician') {
    switchAppointment();
    return null;
  }

  function getClinicianButtons() {
    if (appointmentStatus === 'completed') {
      switchAppointment();
      return null;
    }

    if (appointmentStatus === 'in-progress') {
      return (
        <div>
          <div
            className="action-grid"
            css={css`
              display: grid;
              width: 100%;
              grid-gap: 1rem;
              margin-left: 5px;
              grid-template-columns: 1fr 20fr 20fr 1fr;
            `}
          >
            <span />
            <Button
              onClick={() => startAppointment()}
              disabled={rescheduleRequested || onSessionWindow}
              data-testid="return-to-appointment"
            >
              Return to appointment
            </Button>
            <Tooltip placement="top" title={<span>Started appointments cannot be delayed</span>} zIndex={100000001}>
              <Button data-testid="delay-button" disabled>
                Delay by 5 minutes
              </Button>
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div
        className="action-grid"
        css={css`
          display: grid;
          width: 100%;
          grid-gap: 1rem;
          margin-left: 5px;
          grid-template-columns: 1fr 20fr 20fr 1fr;
        `}
      >
        <span />

        <Button onClick={() => startAppointment()} disabled={rescheduleRequested} data-testid="start-appointment">
          {Math.floor(counter / 60) > 0 ? 'Start appointment' : 'Go to appointment room'}
        </Button>

        <ToggleDelayButton disabled={rescheduleRequested || delayInMinutes >= 10} data-testid="toggle-delay-button" />
        <span />

        <span />
        <Button
          disabled={rescheduleRequested}
          onClick={() => {
            requestReschedule();
          }}
          data-testid="cancel-appointment"
        >
          Cancel appointment
        </Button>

        <span />
        <span />
      </div>
    );
  }

  function getPatientButtons() {
    if (appointmentStatus === 'completed') {
      return null;
    }

    if (appointmentStatus === 'in-progress') {
      return (
        <Button
          onClick={() => startAppointment()}
          disabled={rescheduleRequested || onSessionWindow}
          data-testid="return-to-appointment"
        >
          Return to appointment
        </Button>
      );
    }

    return (
      <Button
        className="slideup-button"
        disabled={rescheduleRequested}
        onClick={() => startAppointment()}
        data-testid="go-to-appointment-room"
      >
        {Math.floor(counter / 60) > 0 ? 'Wait in appointment room' : 'Go to appointment room'}
      </Button>
    );
  }

  // patient wants all the existing functionality without being visible.
  // only info modal about appointment being cancelled can be shown to patient
  if (myRole === 'patient') {
    return (
      <div id="special">
        <InfoModal title="Reschedule Request" open={displayRescheduleModal} onOk={cancellationAcknowledged}>
          {patientStatus !== 'in-session' && <h2>Reschedule Request</h2>}
          <CancellationApology />
          {patientStatus !== 'in-session' && (
            <Button onClick={cancellationAcknowledged} data-testid="acknowledge-appointment-cancellation">
              Ok
            </Button>
          )}
        </InfoModal>
      </div>
    );
  }

  return (
    <div
      className={classNames}
      data-testid={`appointment-${appointmentWait.appointment_id}`}
      css={appointmentWaitStyles}
    >
      <CancelAppointmentModal
        appointment={appointmentToCancel}
        onCancelAppointment={(reason) => cancelledAppointment(reason)}
        cancelModalVisible={cancelModalVisible}
        setCancelModalVisible={setCancelModalVisible}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div role="banner" className="slideup-toolbar" onClick={() => slideAnimate()}>
        {renderTitle()}
        <FiChevronDown
          id="slideup-chevron"
          className="rotate"
          style={{
            position: 'absolute',
            right: 10,
            top: '1%',
            color: 'white',
            cursor: 'pointer',
            height: 30,
            width: 20
          }}
        />
      </div>

      <div className="slideup-contents">
        <div className="slideup-main">
          <button
            type="button"
            style={hasPrevious ? {} : { transform: 'translateY(-10000px)' }}
            disabled={!hasPrevious}
            onClick={() => onPrevious && onPrevious()}
            data-testid="previous-appointment"
          >
            &lt;&lt;
          </button>
          <span className="slideup-patient-img">
            {upcomingHistory && upcomingHistory[0]?.clinician_user_guid && (
              <Image src={avatar} alt={upcomingHistory[0].name} width="55px" height="55px" />
            )}
          </span>

          <span className="slideup-patient-details">
            <p className="slideup-patient-name">
              {pageType === 'patients' ? appointmentWait?.clinician : appointmentWait?.patient}
            </p>
            <p className="slideup-patient-compl">{appointmentWait?.appt_type_desc}</p>
            <p className="their-status">{myRole === 'clinician' ? theirStatus : delayStatus}</p>
          </span>
          {pageType === 'patients' && appointmentWait.call_type_code === 'AUDIO' ? (
            ''
          ) : (
            <span className="slideup-timer-section">
              <div className="slideup-timer">
                <RenderTime withoutStatus />
              </div>
              <div className="slideup-minsec">
                <p className="slideup-min">minutes</p>
                <p className="slideup-sec">seconds</p>
              </div>
            </span>
          )}
          <button
            type="button"
            style={hasNext ? {} : { transform: 'translateY(-10000px)' }}
            disabled={!hasNext}
            onClick={() => onNext && onNext()}
            data-testid="next-appointment"
          >
            &gt;&gt;
          </button>
        </div>

        <div className="slideup-description">{renderContent()}</div>

        <div className="slideup-buttons">
          {pageType === 'patients' && appointmentWait.call_type_code !== 'AUDIO' && getPatientButtons()}
          {pageType === 'clinician' && getClinicianButtons()}
        </div>
      </div>
    </div>
  );
};

// is the start of the given appointment within +/- 20 minutes of now?
// i.e. 20 minutes before the start or up to 20 minutes after it *should* have started
function isImminent({ appointment_completed, datetime }, VIDEO_START_BUFFER) {
  const start = new Date(datetime);
  const now = new Date();

  if (appointment_completed) return false;

  const result = isWithinInterval(now, {
    start: subMinutes(start, 10 + (Number(VIDEO_START_BUFFER) || 0)), // Show AppointmentWait 10 minutes before the appointment can begin
    end: addMinutes(start, 10 + 10) // 10 minutes after the nominal start + 10 minutes max delay
  });

  console.log({ appointment_completed, datetime, result });
  return result;
}

const WrappedAppointmentWait = ({ className }: { className?: string }) => {
  const [, setRefresh] = useState(0);
  const { CLINICIAN_APPOINTMENT_REFRESH_INTERVAL_SECS = 15 * 60, VIDEO_START_BUFFER } = useRuntimeConfig();
  const [appointmentIndex, setAppointmentIndex] = useState(0);
  const [exposed, setExposed] = useState(false);
  const { pathname } = useRouter();
  const pageType = getPageType(pathname);
  const { appointments, refetchAppointments: refetchTodaysAppointments } = useTodaysAppointments(
    CLINICIAN_APPOINTMENT_REFRESH_INTERVAL_SECS * 1000
  );
  const { invalidateCalendarEvents } = useClinicianDiary({ invalidateOnly: true });
  const upcomingAppointments = useMemo(
    () => appointments.filter(({ clin_start, clin_end }) => (pathname.includes('/session') ? !clin_start : !clin_end)),
    [appointments, pathname]
  );

  // every minute re-evaluate what you consider imminent. So even if the footer does not rerender for a long period, this
  // component *will*, and may therefore create a new AppointmentWait object that is now considered imminent.
  useEffect(() => {
    const interval = setInterval(() => setRefresh((r) => r + 1), 60 * 1000);

    return () => clearInterval(interval);
  }, [setRefresh]);

  // this stops a React development error message warning about AppointmentWait updating this components state whilst
  // rendering
  function _setAppointmentIndex(i) {
    setTimeout(() => setAppointmentIndex(i), 0);
  }

  const invalidateUserSchedule = () => {
    setAppointmentIndex(0);
    refetchTodaysAppointments();
    if (pageType === 'clinician') {
      invalidateCalendarEvents();
    }
  };

  const imminentAppointments = upcomingAppointments.filter((a) => isImminent(a, VIDEO_START_BUFFER));

  // Edge case, when only one item left in imminentAppointments while appointmentIndex is 1.
  // In this case visible prop to AppointmentWait become falsy, so we have to reset appointmentIndex manually.
  useEffect(() => {
    if (!imminentAppointments.length) {
      return;
    }
    if (appointmentIndex > imminentAppointments.length - 1) {
      setAppointmentIndex(0);
    }
  }, [appointmentIndex, imminentAppointments.length]);

  // "render" imminent appointments, which may display a notification for the clinician.
  return (
    <>
      {imminentAppointments.map((appointment, index) => (
        <AppointmentWait
          key={appointment.appointment_id}
          className={className}
          pageType={pageType}
          appointmentWait={appointment}
          invalidateUserSchedule={invalidateUserSchedule}
          hasNext={index < imminentAppointments.length - 1}
          hasPrevious={index > 0 && imminentAppointments.length > 0}
          visible={index === appointmentIndex}
          exposed={exposed}
          onExposed={() => setExposed(true)}
          onCollapse={() => setExposed(false)}
          onNext={() => _setAppointmentIndex(Math.min(index + 1, imminentAppointments.length - 1))}
          onPrevious={() => _setAppointmentIndex(Math.max(index - 1, 0))}
        />
      ))}
    </>
  );
};

export default WrappedAppointmentWait;
